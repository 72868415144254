<template>
  <div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      url: ''
    }
  },
  mounted () {
    const url = this.$route.query['url']
    if (url) {
      this.url = url
      this.go()
    }
  },
  methods: {
    go () {
      window.location.href = this.url
    }
  },
}
</script>

<style lang="scss" scoped>
</style>